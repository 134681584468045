.zoom {
    position: relative;
    overflow: clip !important;
}

.zoom img {
    width: 700px;
    height: auto;
    display: block;
    transition: transform 4s ease-in-out, opacity 2s ease-in-out;
    transform: scale(1);
    opacity: 1;
    justify-items: center !important;
    overflow: clip !important;
}

.zoom.active img {
    transform: scale(1.3);
    opacity: 1;
    overflow: hidden;
}

.caption-top-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: white;
    z-index: 2;
   
}

.zoom::before {

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
    pointer-events: none;
}
.subTitle{
    font-size: 15px;
    font-family: Montserrat, serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    justify-content: right;
    margin-right: 20px;
}

.title{
    font-size: 28px;
    font-family: Roboto, serif;
    font-weight: 500;
    color: white;
}
.blackTitle{
    font-size: 18px;
    font-family: Roboto, serif;
    font-weight: 500;
    color: black;
}
.description{
    font-size: 15px;
    font-family: Montserrat, serif;
    font-weight: 600;
    color: white;
    
}
.blackDescription{
    font-size: 15px;
    font-family: Montserrat, serif;
    font-weight: 400;
    color: black;
}
.eventImg{
    width: 100%;
    height: auto;
}
.counter{
    width: 100%;
    background-size: 100vh;
    margin: 0 auto;
    height: 280px;
    background: url("../../Assets/Images/Banner/background.png") no-repeat fixed center;
    background-size: 100vw 100vh;
    display: block;
}
.counterOverlay {
    width: 100%;
    background-size: cover;
    height: 280px;
    background: rgba(0, 0, 0, 0.3)no-repeat fixed center;
    
    
}
.banner{
    max-width: 1200px;
    max-height: 800px;
    margin: 0 auto;
    
}

.regiform{
    max-width: 1000px; /* Maximum width of the form */
    margin: auto auto; /* Center the form horizontally */
    padding: 50px; /* Padding inside the form */
    border: 1px solid #ff0000; /* Optional: border for better visibility */
    border-radius: 4px;
}

.responsive-img {
   
    width: 1000px; /* Adjust width as needed */
    height: auto;
    align-items: center !important;
    /* height: auto; Maintain aspect ratio */
    display: block; /* Ensure the image is a block-level element */
}

.footer{
    background: linear-gradient(to right, #000000, #ff0000); /* Gradient from left to right */
    padding-top: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    border: 1px solid #cdcdcd;
}

.mt-2{
    background: linear-gradient(to right, #000000, #ab0000); /* Gradient from left to right */
    padding-top: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    border: 1px solid #cdcdcd;
}

.mt-4{
    padding-top: 200px;
}


.formcss{
    padding-top: 40px;
    padding-bottom: 100px;
}


.regiDate{
    padding-top: 50px;
    padding-bottom: 30px;
}

.prizes{
    padding-bottom: 30px;
}

.mt-0{
    padding-bottom: 30px;
}
