@media screen and (max-width: 1250px) {
    .topNav {

        background-color: hsl(0, 0%, 0%);
    }

    .smallNav {
        background-color: #ffffff;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}

/* 
.slider-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding-left: 400px;
    padding-right: 420px;
}

.image-slider {
    position: relative;
    width: 300%;
    height: 100%;
    display: flex;
    transition: transform 1s ease-in-out;
}

.slide {
    flex: 1;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    transition: filter 1s ease-in-out;
}

.middle {
    filter: blur(0);
}
 */


@media(max-width: 800px) {
    .responsive-img {

        width: 100%;
        /* Adjust width as needed */
        height: auto;
        align-items: center !important;
        /* height: auto; Maintain aspect ratio */
        display: block;
        /* Ensure the image is a block-level element */
    }
}