.topNav {
    background-color: #161F37;
    background-position: right;
    width: 40%;
    height: 40px;
    justify-content: right;
}

.zoom {
    position: relative;
}

.icon {
    font-size: 25px;
    color: rgb(0, 61, 214);
}

.joinBtn {
    background-color: #db0000;
    border: 1px solid rgb(255, 255, 255);
    font-family: Montserrat, serif;
    font-size: 15px;
}

.joinBtn:hover {
    background-color: rgb(255, 0, 0);
    color: rgb(118, 0, 0);
    border: none;
    transition: 0.5s !important;

}

.mainNavBody {
    width: 100%;
    height: 100px;
    padding: 0 30px;
    background-color: rgb(255, 255, 255);
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-around;
   
   border-bottom: 1px solid #e0e0e0;
}

.logo {
    width: 115px;
    height: 70px;
    justify-content: left;
}

@media (max-width: 1250px) {
    .logo {
        padding-left: 0;
        width: 80px;
        max-width: 100%;
        height: auto;
        justify-content: left;

    }
}

.headerImage {
    padding-left: 20px;
    width: 500px;
    height: 70px;
    max-width: 100%;
    height: auto;

}

@media (max-width: 1250px) {
    .headerImage {
        padding-left: 0;
        width: 250px;
        max-width: 100%;
        height: auto;

    }
}

.navLink {
    color: #db0000 !important;
    font-size: 16px;
    font-family: Montserrat, serif;
    font-weight: bold;
    background-color: transparent;
    border: none;
    padding-inline-end: 35px;
    justify-content: right;
}

.dropDown {
    color: #000000;
    font-size: 16px;
    font-family: Montserrat, serif;
    font-weight: 500;
    background-color: transparent;
    border: none;
    margin-top: 2px;
    font-weight: bold;
    justify-content: right;

}

.navLink:hover {
    color: rgb(0, 0, 0) !important;
    background-color: transparent;
    border: none;
    justify-content: right;
}


.dropdown-toggle {
    background-color: transparent !important;
    justify-content: right;
}


.navbar-toggler {
    background-color: #b10000;
    display: flex;
    justify-content: right;
}


.contacts {
    justify-content: right;

    padding-top: 20px;
    display: flex;
    flex-direction: row;
    color: #000000;
    font-size: 16px;
    font-family: Montserrat, serif;
    font-weight: bold;

}

.pageMenu {
    justify-content: end;
    display: flex;
    /* flex-direction: row; */
    padding-top: 10px;
    justify-content: right;

}


.rightMenu {

    display: flex;
    flex-direction: column;
    justify-content: right;
    background-color: rgb(255, 255, 255);

}

@media (max-width: 993px) {
    .mainNavBody {
        width: 100%;
        height: 180px;
        padding: 0 30px;
        background-color: rgb(255, 255, 255);
        padding-bottom: 20px;
        padding-top: 20px;
        justify-content: space-around;
    }
}

@media (max-width: 650px) {
    .rightMenu {
        display: flex;
        flex-direction: column;
        justify-content: right;
        background-color: rgb(255, 255, 255);


    }

    .contacts {
        justify-content: right;

        padding-top: 20px;
        display: flex;

        flex-direction: column;
        color: #000000;
        font-size: 13px;
        font-family: Montserrat, serif;
        font-weight: bold;
        padding-bottom: 10px;

    }

    .pageMenu {
        justify-content: right;
        display: flex;
        flex-direction: column;
        padding-top: 10px;

    }

    .mainNavBody {
        width: 100%;
        height: 200px;
        padding: 0 30px;
        background-color: rgb(255, 255, 255);
        padding-bottom: 20px;
        padding-top: 20px;
        justify-content: space-around;
    }

    .responsive-img {
   
        width: 100%; /* Adjust width as needed */
        height: auto;
        align-items: center !important;
        /* height: auto; Maintain aspect ratio */
        display: block; /* Ensure the image is a block-level element */
    }
    

}