.form-control{
    box-shadow: none!important;
}
.box{
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(212,212,212,0.65);
    -moz-box-shadow: 0px 0px 5px 1px rgba(212,212,212,0.65);
    box-shadow: 0px 0px 5px 1px rgba(212,212,212,0.65);
}
.next{
    position: absolute;
    right: 0;
}